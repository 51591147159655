var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb-4" }, [
      _c(
        "div",
        [
          _c(
            "vs-button",
            {
              attrs: { type: "border", icon: "undo" },
              on: {
                click: function ($event) {
                  return _vm.$router.push(
                    "/proctoring_monitor/" + _vm.questionnaireId
                  )
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.back")))]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "grid mb-4" },
      [
        _c("vx-card", { staticClass: "supervidor-div" }, [
          _c(
            "div",
            { staticClass: "pb-4 flex gap-6" },
            [
              _vm.roomIdentifier
                ? _c(
                    "div",
                    { staticClass: "flex flex-col sm:flex-row sm:gap-2" },
                    [
                      _c("label", { staticClass: "font-bold" }, [
                        _vm._v("Sala:"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.roomIdentifier))]),
                    ]
                  )
                : _vm._e(),
              _vm.questionnaire
                ? _c("questionnaire-information", {
                    attrs: { questionnaire: _vm.questionnaire },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "grid grid-rows-1 auto-cols-max gap-2 w-full justify-center sm:justify-between md:justify-between",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col col-span-2 md:col-span-auto gap-0 md:gap-4 row-start-1",
                },
                [
                  _c("div", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("supervisor"))),
                  ]),
                  _c("div", {
                    directives: [
                      {
                        name: "html-safe",
                        rawName: "v-html-safe",
                        value: _vm.supervisorName,
                        expression: "supervisorName",
                      },
                    ],
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col gap-0 md:gap-4 row-start-2 md:row-start-1",
                },
                [
                  _c("div", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("ativos"))),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.activeUsersAmount))]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col gap-0 md:gap-4 row-start-2 md:row-start-1",
                },
                [
                  _c("div", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("finalizados"))),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.finishedUsersAmount))]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "proctoring.monitor",
                      expression: "'proctoring.monitor'",
                    },
                  ],
                  staticClass:
                    "\n            flex flex-row gap-2 items-center sm:items-start md:items-center justify-end\n            w-full sm:w-auto\n            row-start-3 sm:row-start-1\n            row-span-3 md:row-span-auto\n            col-span-2 sm:col-span-auto\n          ",
                },
                [
                  !_vm.beingSupervised
                    ? _c(
                        "div",
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-play",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.superviseThis()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("iniciar-supervisao")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.beingSupervised && _vm.currentUserIsSupervisor
                    ? _c(
                        "div",
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                color: "danger",
                                "icon-pack": "feather",
                                icon: "icon-stop-circle",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.exitSupervision()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.supervisedIsActiveUser
                                      ? _vm.$t("sair-supervisao")
                                      : _vm.$t("encerrar-supervisao")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "grid grid-cols-12 grid-rows-6 gap-x-2" }, [
      _c(
        "div",
        {
          class: [
            "row-span-6 h-full",
            _vm.activeSidebar ? "col-span-8" : "col-span-12",
          ],
        },
        [
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value: {
                      content: _vm.expandTooltipContent,
                    },
                    expression:
                      "{\n            content: expandTooltipContent\n          }",
                    modifiers: { top: true },
                  },
                ],
                staticClass: "float-right ml-4 cursor-pointer",
                staticStyle: { "font-size": "18px" },
                attrs: { icon: "bars" },
                on: {
                  click: function ($event) {
                    _vm.activeSidebar = !_vm.activeSidebar
                  },
                },
              }),
              _vm.isLiveChat
                ? _c("font-awesome-icon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top",
                        value: {
                          content: "Habilitar/desablitar todas chamadas",
                        },
                        expression:
                          "{\n            content: 'Habilitar/desablitar todas chamadas'\n          }",
                        modifiers: { top: true },
                      },
                    ],
                    staticClass: "ml-4 cursor-pointer",
                    staticStyle: { "font-size": "18px" },
                    attrs: { icon: "phone" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleAllSupport()
                      },
                    },
                  })
                : _vm._e(),
              _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.left",
                    value: {
                      content: _vm.$t("habilitar-desabilitar-audio-todos"),
                    },
                    expression:
                      "{\n            content: $t('habilitar-desabilitar-audio-todos')\n          }",
                    modifiers: { left: true },
                  },
                ],
                staticClass: "float-right ml-4 cursor-pointer",
                staticStyle: { "font-size": "18px" },
                attrs: { icon: "volume-up" },
                on: {
                  click: function ($event) {
                    return _vm.toggleAllVulumes()
                  },
                },
              }),
            ],
            1
          ),
          !_vm.noOneHere
            ? _c("LiveStreamsContainer", {
                ref: "livestreamcontainer",
                attrs: {
                  "room-id": _vm.roomId,
                  "user-media-stream": _vm.userMediaStream,
                  "proctoring-service": _vm.service,
                  config: _vm.room.config,
                },
                on: {
                  "stream-action": _vm.streamAction,
                  "classroom-new-event": _vm.classroomNewEvent,
                  supervise: function ($event) {
                    return _vm.superviseThis()
                  },
                },
                model: {
                  value: _vm.streams,
                  callback: function ($$v) {
                    _vm.streams = $$v
                  },
                  expression: "streams",
                },
              })
            : _c("edu-placeholder-action", {
                attrs: { label: _vm.$t("aguardando-participantes") },
                on: {
                  action: function ($event) {
                    return _vm.refreshRoom(_vm.roomId)
                  },
                },
              }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeSidebar,
              expression: "activeSidebar",
            },
          ],
          staticClass: "col-span-4 row-span-6",
        },
        [
          _c(
            "div",
            { ref: "parentSidebar", attrs: { id: "parent-users" } },
            [
              _c(
                "vs-sidebar",
                {
                  staticClass: "parent-users-sidebar",
                  attrs: {
                    parent: _vm.$refs.parentSidebar,
                    "default-index": "1",
                    color: "primary",
                    spacer: "",
                  },
                  model: {
                    value: _vm.activeSidebar,
                    callback: function ($$v) {
                      _vm.activeSidebar = $$v
                    },
                    expression: "activeSidebar",
                  },
                },
                [
                  _c(
                    "vs-tabs",
                    [
                      _c(
                        "vs-tab",
                        {
                          staticClass: "overflow-x-hidden overflow-y-auto",
                          style: _vm.tabElementStyle,
                          attrs: { label: _vm.$t("participantes") },
                        },
                        [
                          _vm.noOneHere
                            ? _c("edu-placeholder-action", {
                                attrs: {
                                  label: _vm.$t("aguardando-participantes"),
                                },
                                on: {
                                  action: function ($event) {
                                    return _vm.refreshRoom(_vm.roomId)
                                  },
                                },
                              })
                            : _c(
                                "div",
                                [
                                  _c("proctoring-classroom-user-list", {
                                    attrs: { users: _vm.unfinishedStreams },
                                    on: {
                                      highlight: function ($event) {
                                        return _vm.expandStreamPlayer(
                                          $event.user.id
                                        )
                                      },
                                    },
                                  }),
                                  _c("proctoring-classroom-user-list", {
                                    attrs: {
                                      users: _vm.finishedStreams,
                                      enabled: false,
                                      itemStyling: function (stream) {
                                        return {
                                          container:
                                            "py-2 flex flex-row gap-x-2 " +
                                            (stream.user.finished_at
                                              ? "opacity-25"
                                              : ""),
                                        }
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      !_vm.noOneHere && _vm.room && _vm.room.events
                        ? _c(
                            "vs-tab",
                            {
                              staticClass: "overflow-x-hidden",
                              style: _vm.tabElementStyle,
                              attrs: { label: _vm.$t("eventos") },
                            },
                            [
                              _vm.room.events.length === 0
                                ? _c("edu-placeholder-action", {
                                    attrs: {
                                      label: _vm.$t("ainda-nao-ha-eventos"),
                                    },
                                    on: {
                                      action: function ($event) {
                                        return _vm.refreshRoom(_vm.roomId)
                                      },
                                    },
                                  })
                                : _c("proctoring-classroom-event-list", {
                                    attrs: {
                                      events: _vm.room.events,
                                      streams: _vm.streams,
                                      showFilter: true,
                                      showUserFilter: true,
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }