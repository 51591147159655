<template>
  <transition-group name="zoom-fade" class="grid grid-cols-1 md:grid-cols-2 gap-4 pr-4" tag="div">
    <div v-for="stream of unfinishedStreams" :key="stream.user.id" :class="{'col-span-2': expendedUserId === stream.user.id}">
      <StreamPlayer
        :roomId="roomId"
        :user="stream.user"
        :answerId="stream.questionnaire_answer_id"
        :key="stream.user.finished_at"
        :finished="stream.user.finished_at"
        @expanded="expand"
        :proctoring-service="proctoringService"
        :media-stream="userMediaStream"
        @supervise="startSupervision"
        :ref="`stream-${stream.user.id}`"
        :config="config"
        >
      </StreamPlayer>
    </div>
  </transition-group>
</template>

<script>

import ProctoringService from '@/services/api/ProctoringService'
import StreamPlayer from '@/views/apps/proctor/StreamPlayer'

export default {

  model: {
    prop: 'streams',
    event: 'stream-changed'
  },

  components: {
    StreamPlayer
  },
  
  props: {
    roomId: {
      type: Number,
      default: null
    },
    streams: {
      type: Array,
      default: null
    },
    // Auditing mode
    auditing: {
      type: Boolean,
      default: false
    },
    proctoringService: {
      type: ProctoringService,
      default: null
    },
    userMediaStream: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    expendedUserId: null
  }),

  computed: {
    unfinishedStreams() {
      return this.streams.filter(
        (stream) => !stream.finished_at
      )
    }
  },

  methods: {

    expand(userId) {
      this.expendedUserId = userId

      // Order the players and put the expanded on the top
      if (!this.isEmpty(this.expendedUserId)) {
        const index = this.streams.findIndex(stream => {
          return stream.user.id === this.expendedUserId
        })
        this.streams.unshift(this.streams.splice(index, 1)[0])
      }

      this.$root.$emit('collapse_all', userId)
    },

    startSupervision(user) {
      this.$emit('supervise', user)
    }
    
  },

  mounted() {

  },

}
</script>