<template>
  <div>
    <div class="mb-4">
      <div>
        <vs-button
          type="border"
          @click="$router.push(`/proctoring_monitor/${questionnaireId}`)"
          icon="undo"
        >{{ $t('common.back') }}</vs-button>
      </div>
    </div>
    <div class="grid mb-4">
      <vx-card class="supervidor-div">
        <div class="pb-4 flex gap-6">
          <div v-if="roomIdentifier" class="flex flex-col sm:flex-row sm:gap-2">
            <label class="font-bold">Sala:</label>
            <span>{{ roomIdentifier }}</span>
          </div>
          <questionnaire-information v-if="questionnaire" :questionnaire="questionnaire"/>
        </div>
        <div class="grid grid-rows-1 auto-cols-max gap-2 w-full justify-center sm:justify-between md:justify-between">
          <div class="flex flex-col col-span-2 md:col-span-auto gap-0 md:gap-4 row-start-1">
            <div class="font-bold">{{ $t('supervisor') }}</div>
            <div v-html-safe="supervisorName"></div>
          </div>
          <div class="flex flex-col gap-0 md:gap-4 row-start-2 md:row-start-1">
            <div class="font-bold">{{ $t('ativos') }}</div>
            <div>{{activeUsersAmount}}</div>
          </div>
          <div class="flex flex-col gap-0 md:gap-4 row-start-2 md:row-start-1">
            <div class="font-bold">{{ $t('finalizados') }}</div>
            <div>{{finishedUsersAmount}}</div>
          </div>
          <div
            class="
              flex flex-row gap-2 items-center sm:items-start md:items-center justify-end
              w-full sm:w-auto
              row-start-3 sm:row-start-1
              row-span-3 md:row-span-auto
              col-span-2 sm:col-span-auto
            "
            v-permission="'proctoring.monitor'"
          >
            <div v-if="!beingSupervised">
              <vs-button
                @click="superviseThis()"
                icon-pack="feather"
                icon="icon-play"
              >{{ $t('iniciar-supervisao') }}</vs-button>
            </div>
            <div v-if="beingSupervised && currentUserIsSupervisor">
              <vs-button
                color="danger"
                @click="exitSupervision()"
                icon-pack="feather"
                icon="icon-stop-circle"
              >
              {{ supervisedIsActiveUser ? $t('sair-supervisao') : $t('encerrar-supervisao')}}
              </vs-button>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="grid grid-cols-12 grid-rows-6 gap-x-2">
      <div :class="['row-span-6 h-full', activeSidebar ? 'col-span-8' : 'col-span-12']">
        <div class="float-right">
          <font-awesome-icon
            @click="activeSidebar=!activeSidebar"
            icon="bars"
            class="float-right ml-4 cursor-pointer"
            style="font-size: 18px"
            v-tooltip.top="{
              content: expandTooltipContent
            }"
          />

          <font-awesome-icon
            v-if="isLiveChat"
            @click="toggleAllSupport()"
            icon="phone"
            class="ml-4 cursor-pointer"
            style="font-size: 18px"
            v-tooltip.top="{
              content: 'Habilitar/desablitar todas chamadas'
            }"
          />

          <font-awesome-icon
            @click="toggleAllVulumes()"
            icon="volume-up"
            class="float-right ml-4 cursor-pointer"
            style="font-size: 18px"
            v-tooltip.left="{
              content: $t('habilitar-desabilitar-audio-todos')
            }"
          />
        </div>
        <LiveStreamsContainer
          v-if="!noOneHere"
          v-model="streams" 
          :room-id="roomId" 
          :user-media-stream="userMediaStream"
          @stream-action="streamAction"
          @classroom-new-event="classroomNewEvent"
          @supervise="superviseThis()"
          :proctoring-service="service"
          :config="room.config"
          ref="livestreamcontainer"
        />
        <edu-placeholder-action v-else :label="$t('aguardando-participantes')" @action="refreshRoom(roomId)"/>
      </div>
      <div class="col-span-4 row-span-6" v-show="activeSidebar">
        <div ref="parentSidebar" id="parent-users">
          <vs-sidebar :parent="$refs.parentSidebar" default-index="1"  color="primary" class="parent-users-sidebar" spacer v-model="activeSidebar">
            <vs-tabs>
              <vs-tab :label="$t('participantes')" class="overflow-x-hidden overflow-y-auto" :style="tabElementStyle">
                <edu-placeholder-action v-if="noOneHere" :label="$t('aguardando-participantes')" @action="refreshRoom(roomId)"/>
                <div v-else>
                  <proctoring-classroom-user-list
                    :users="unfinishedStreams"
                    @highlight="expandStreamPlayer($event.user.id)"/>
                  <proctoring-classroom-user-list
                    :users="finishedStreams"
                    :enabled="false"
                    :itemStyling="(stream) => {
                      return {
                        container: `py-2 flex flex-row gap-x-2 ${stream.user.finished_at ? 'opacity-25' : ''}`,
                      }
                    }"
                  />
                </div>
              </vs-tab>
              <vs-tab
                v-if="!noOneHere && room && room.events"
                :label="$t('eventos')"
                class="overflow-x-hidden"
                :style="tabElementStyle"
              >
                <edu-placeholder-action v-if="room.events.length === 0" :label="$t('ainda-nao-ha-eventos')" @action="refreshRoom(roomId)"/>
                <proctoring-classroom-event-list
                  v-else
                  :events="room.events"
                  :streams="streams"
                  :showFilter="true"
                  :showUserFilter="true"
                />
              </vs-tab>
            </vs-tabs>
            
          </vs-sidebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LiveStreamsContainer from './components/LiveStreamsContainer.vue'
import ProctoringService from '@/services/api/ProctoringService'
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'
import ProctoringClassroomUserList from './components/ProctoringClassroomUserList.vue'
import ProctoringClassroomEventList from './components/ProctoringClassroomEventList.vue'
import { PROCTORING_STREAM_TYPE } from '@/util/Enums'
import { isArray } from 'lodash'
import { arraySort } from '@/util/Util'
import QuestionnaireInformation from '../questionnaires/events/QuestionnaireInformation.vue'
import { NotificationActionCodes } from '@/util/Exceptions'

export default {
  
  props: {
    roomId: {
      type: Number,
      default: null
    }
  },

  components: {
    LiveStreamsContainer,
    EduPlaceholderAction,
    ProctoringClassroomUserList,
    ProctoringClassroomEventList,
    QuestionnaireInformation
  },

  data: () => ({
    service: null,
    streams: [],
    room: null,
    showingEventsFor: null,
    supervisors: [],
    activeSidebar: true,
    volumeEnabled: false,
    supportEnabled: false,
    userMediaStream: null
  }),

  computed: {
    roomIdentifier() {
      return this._.get(this.room, 'classroom_identifier', null)
    },
    questionnaire() {
      return this._.get(this.room, 'config.questionnaire', null)
    },
    noOneHere() {
      return this.streams && this.streams.length === 0
    },
    beingSupervised() {
      return (this.room && this.room.supervised) || false
    },
    supervisorName() {
      let name = this.$t('nenhum-supervisor-ativo')

      if (this.room && 'supervised' in this.room && this.room.supervised && 'name' in this.room.supervised) {
        name = this.room.supervised.name
      }
      
      return name
    },
    supervisorId() {
      let id = null
      if (this.room && 'supervised' in this.room && this.room.supervised && 'id' in this.room.supervised) {
        id = parseInt(this.room.supervised.id)
      }
      return id
    },
    activeUsersAmount() {
      return parseInt(this._.get(this.room, 'active_users_amount', 0))
    },
    finishedUsersAmount() {
      const activeUsers = this.activeUsersAmount
      const usersAmount = parseInt(this._.get(this.room, 'users_amount', 0))
      
      if (usersAmount > 0) {
        return parseInt(usersAmount) - parseInt(activeUsers)
      } else {
        return 0
      }
    },
    tabElementStyle() {
      // TODO Implement dynamic height based on free screen space
      const availableHeight = screen.availHeight
      // return ''
      return 'height: 470px;'
    },
    currentUserIsSupervisor() {
      const me = this.$store.getters.AppActiveUser
      if (me && 'supervisors' in this.room && isArray(this.room.supervisors)) {
        return !!(this.room && this.room.supervisors.find((supervisor) => supervisor.id === me.id))
      }
      return null
    },
    supervisedIsActiveUser() {
      const me = this.$store.getters.AppActiveUser
      return this.room.supervised && this.room.supervised.id === me.id
    },
    finishedStreams() {
      const streams = this.streams
      if (streams && this._.isArray(streams)) {
        const finishedStreams = streams.filter((stream) => stream.user.finished_at).sort(arraySort('user.name', 1, true))
        return finishedStreams.map(
          (stream) => {
            const equals = streams.filter((_stream) => {
              return _stream.user.id === stream.user.id
            })

            if (equals.length > 1) {
              stream.showAnswerIndicator = true
            }

            return stream
          }
        )
      } else {
        return []
      }
    },
    unfinishedStreams() {
      const streams = this.streams
      if (streams && this._.isArray(streams)) {
        const unfinishedStreams = streams.filter((stream) => !stream.user.finished_at).sort(arraySort('user.name', 1, true))
        return unfinishedStreams.map(
          (stream) => {
            const equals = streams.filter((_stream) => {
              return _stream.user.id === stream.user.id
            })

            if (equals.length > 1) {
              stream.showAnswerIndicator = true
            }

            return stream
          }
        )
      } else {
        return []
      }
    },
    isLiveChat() {
      if (this.room && this.room.config) {
        return this.room.config.record_medium === PROCTORING_STREAM_TYPE.LiveChat || this.room.config.record_medium === PROCTORING_STREAM_TYPE.LiveChatRecord
      }
      return false
    },
    questionnaireId() {
      return this._.get(this.room, 'config.questionnaire_id', null)
    }
  },

  beforeDestroy() {
    if (this.roomId) {
      window.Echo.leaveChannel(`private-App.Models.Cnt.Proctor.ProctoringClassroom.${this.roomId}`)
    } else {
      this.notifyError(this.$vs, this.$t('erro-de-desconstrucao-por-favor-entre-em-contato-com-o-suporte'))
    }
  },

  mounted() {
    this.service = ProctoringService.build(this.$vs)
    if (this.roomId) {
      this.loadRoom(this.roomId)
    }

    this.$root.$on('new_event_added', () => {
      this.refreshRoom(this.roomId)
    })

    if (window.Echo) {
      window.Echo.private(`App.Models.Cnt.Proctor.ProctoringClassroom.${this.roomId}`).notification(
        (notification) => {
          if (notification && notification.type === 'proctoring_classroom.new_user') {
            
            if (notification.stream && notification.stream.user) {
              const userExists = this.streams.findIndex((stream) => {
                return stream.user.classroom_user_id === notification.stream.user.classroom_user_id
              })

              if (userExists === -1) {
                this.streams.push(notification.stream)
                this.notifyWarning(this.$vs, this.$t('novo-participante-this-truncate-notification-stream-user-name-adicionado-a-sala', [this.truncate(notification.stream.user.name)]), 5000)
              } else {

                // Update it instead of adding a new one entirely.
                this.streamAction({type: 'update', stream: notification.stream})
                
                let element = null
                this.livestreamContainer((container) => {
                  element = container.$refs[`stream-${notification.stream.user.id}`]
                } )

                if (element && element[0]) {
                  element.forEach((element) => element.refresh(true))
                }

                const userName = this._.get(notification, 'stream.user.name', null)

                this.notifyWarning(this.$vs, this.$t('username-o-um-participante-this-truncate-notification-stream-user-name-acaba-de-entrar-novamente', [userName ? this.$t('o') : this.$t('um'), this.truncate(notification.stream.user.name)]), 5000)

              }
            }
            
          } else if (notification && notification.type === 'proctoring_classroom.new_event') {
            this.classroomNewEvent(notification.event)
          } else if (notification && notification.type === 'proctoring_classroom.user_finished') {
            if (notification.stream && notification.stream.user) {
              const indexToAlter = this.streams.findIndex((stream) => {
                return stream.user.id === notification.stream.user.id
              })
              if (indexToAlter !== -1) {
                const toAlter = this.streams[indexToAlter]

                // We need to alter finished_at inside of user instead of plainly on stream.
                toAlter.user.finished_at = notification.stream.user.finished_at
                if (toAlter && toAlter.user) {
                  
                  // this.streams[indexToAlter].user.finished_at = notification.stream.user.finished_at
                  this.streamAction({type: 'update', stream: toAlter})
                }
                this.notifyWarning(this.$vs, this.$t('o-participante-this-truncate-notification-stream-user-name-finalizou-o-questionario', [this.truncate(notification.stream.user.name)]), 5000)
              }
            }
          } else if (notification && notification.type === 'proctoring_classroom.update_status') {
            const activeUsers = _.get(notification, 'indicators.active_users_amount')
            const users = _.get(notification, 'indicators.users_amount')

            _.set(this.room, 'active_users_amount', activeUsers)
            _.set(this.room, 'users_amount', users)
          }
        }
      )
    }    
  },

  methods: {
    setRoomData(room) {
      const {streams, ...loadedRoom} = room
      this.room = loadedRoom
      this.streams = streams

      // this.streams = this.$utils.array.uniqBy(this.streams, (item) => {
      //   return item.id
      // })

      this.streams.sort(arraySort('user.name', 1, true))
    },
    handleRoomLoadFailure(error) {
      this.$utils.serverError.onCodeDo(NotificationActionCodes.GOBACK, error,
        (errorCode) => {
          this.$router.push('/proctoring_config')
        },
        (notErrorCode) => {
          this.notifyError(this.$vs, this.$tc('proctoring.room.load_failed', 1), 4000)
        }
      )
    },
    livestreamContainer(callback) {
      const livestreamContainer = this.$refs['livestreamcontainer']
      if (livestreamContainer && this._.isFunction(callback)) {
        callback(livestreamContainer)
      }
    },
    loadRoom(id, captureMediaAndSupervise = true) {
      if (this.service) {
        this.$vs.loading()
        this.service.loadRoom(id).then(
          room => {
            this.$vs.loading.close()

            this.setRoomData(room)
            
            if (this.supervisedIsActiveUser 
                  && this.currentUserIsSupervisor 
                    && this.beingSupervised && captureMediaAndSupervise) {
              this.captureUserMedia(this.roomId)
            }
          },
          error => {
            this.$vs.loading.close()
            this.handleRoomLoadFailure(error)
          }
        )
      }
    },
    refreshRoom(id) {
      this.loadRoom(id, false)
    },
    streamAction(action) {
      if (action && action.type) {
        switch (action.type) {
        case 'open-events':
          this.openEvent(action.stream)
          break
        case 'update':
          this.updateStream(action.stream)
          break
        default:
          break
        }
      }
    },
    updateStream(stream) {
      const streams = this._.cloneDeep(this.streams)
      const where = this.streams.findIndex((_stream) => _stream.user.classroom_user_id === stream.user.classroom_user_id)
      if (where !== -1) {
        streams[where] = stream
        this.streams = streams
      }
    },
    classroomNewEvent(event) {
      if (this.room && this.room.events) {
        this.room.events.unshift(event)
      }
    },
    superviseThis() {
      if (this.roomId) {
        const message = this.$t('nenhuma-informacao-da-sua-camera-ou-microfone-sera-apresentada-para-o-participante-sem-que-realize-uma-chamada')
        this.$vs.dialog({
          type:'confirm',
          color: 'warning',
          title: this.$t('atencao'),
          text: message,
          acceptText: this.$t('sim'),
          cancelText: this.$t('common.back'),
          accept: () => this.captureUserMedia(this.roomId),
        })
      } 
    },
    exitSupervision() {
      this.$vs.dialog({
        type:'confirm',
        color: 'warning',
        title: this.$t('atencao-0'),
        text: this.supervisedIsActiveUser ? this.$t('deseja-parar-de-supervisionar-esta-sala-para-voltar-e-so-clicar-no-botao-iniciar-supervisao-novamente') : this.$t('voce-nao-e-o-supervisor-desta-sav-deseja-forcar-o-encerramento-desta-supervisao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('common.back'),
        accept: () => { this.exitRoomSupervision(this.roomId) },
      })
    },
    captureUserMedia(roomId) {
      if (!this.userMediaStream) {
        this.$vs.loading()
        this.startCaptureUserMedia({
          video: true,
          audio: true
        }, this).then(stream => {
          this.$vs.loading.close()
          this.superviseRoom(roomId, stream)
        }, () => {
          this.$vs.loading.close()
        })
      } else {
        this.superviseRoom(roomId, this.userMediaStream)
      }
    },
    
    superviseRoom(roomId, stream) {
      this.userMediaStream = stream
      this.service.superviseRoom(roomId).then(
        room => {
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('iniciada-supervisao-da-sala'))
          this.room = room
          this.$root.$emit(`start_supervice_room_.${roomId}`)
        },
        error => {
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },

    exitRoomSupervision(roomId) {
      this.$vs.loading()
      this.service.exitRoomSupervision(roomId).then(
        (response) => {

          if (this.userMediaStream) {
            this.stopStream(this.userMediaStream)
          }

          this.$root.$emit('exit_room_supervision')
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('voce-parou-de-supervisionar-esta-sav-com-sucesso'), 3000)
          this.loadRoom(roomId)
        },
        (error) => {
          console.error('Error... ', error)
          this.$vs.loading.close()
        }
      )
    },

    openEvent(stream) {
      this.showingEventsFor = stream
    },
    expandStreamPlayer(userId) {
      this.$root.$emit(`maximize_stream_player.${userId}`)
    },
    toggleAllVulumes() {
      this.volumeEnabled = !this.volumeEnabled
      this.$root.$emit(`toggle_room_volume.${this.roomId}`, this.volumeEnabled)
    },
    toggleAllSupport() {
      if (this.isLiveChat) {
        this.supportEnabled = !this.volumeEnabled
        this.$root.$emit(`toggle_room_support.${this.roomId}`, this.supportEnabled)
      }
    }
  },

  destroyed() {
    if (this.userMediaStream) {
      const tracks = this.userMediaStream.getTracks()
      if (tracks) {
        tracks.forEach(track => {
          track.stop()
          if (this.userMediaStream) {
            this.userMediaStream.removeTrack(track)
          }
        })
      }
    }
  }

}
</script>

<style lang="scss">
#parent-users {
  overflow: hidden;
  height: 500px;
  position: relative;
}

.vs-sidebar--background {
  width: inherit !important;
}

.vs-sidebar--items {
  padding: 0px !important;
}

.parent-users-sidebar > .vs-sidebar {
  max-width: 370px;
}

.supervisor-div > .vx-card__body {
  padding: 1rem !important;
}
</style>